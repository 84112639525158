import React from "react"
import usePosts from "../hooks/use-posts"
// import { Link } from "gatsby"
import { css } from "styled-components"
import Layout from "../layouts/layout"
import PostPreview from "../layouts/post-preview"
// import Image from "../components/image";
import SEO from "../components/seo"
import { Button, UiText, Heading } from "../components"
import { Link } from "gatsby"
const IndexPage = () => {
  const posts = usePosts()
  return (
    <>
      <Layout>
        <SEO title="Home" />
        <div
          css={css`
            position: relative;
            max-width: 960px;
            margin: auto;
            padding: 40px 0px;
          `}
        >
          <article className="first-entry home-info">
            <header className="entry-header">
              <Heading variant="h1" as="h1" level={1}>
                Hardware and Software Engineer (HW/SW)
              </Heading>
            </header>
            <section className="entry-content">
              <UiText variant="content">
                Hi, my name is Souleymane Dembele and I am a Computer Hardware
                and Software Engineer with a strong fundamental computer
                science, computer architecture and engineering concepts in
                Greater Seattle Area. This blog will be used as a medium to
                share my experiences and knowledge throughout my journey
                <span role="img">🌟</span>.
              </UiText>
            </section>
            <footer>
              <Link to="/about">
                <Button
                  css={css`
                    padding: 8px 16px;
                    margin: 0;
                  `}
                >
                  <UiText as="span" margin="0px" variant="subContentBold">
                    Learn more
                  </UiText>
                </Button>
              </Link>
            </footer>
          </article>
          <div>
            <Heading as="h2" variant="h2" level={2}>
              Latest Blog
            </Heading>
            {posts.map(post => (
              <PostPreview key={post.slug} post={post} />
            ))}
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
// I am a software engineer that focuses on front-end tools such as react, react native, and electron. I have a passion for design systems, Serverless technology

// I am a Software Engineer that focuses on front-end tools such as react, angular, and react-native. I have a passion for design systems, web accessibility, serverless technologies such as AWS and Google Cloud, and Artificial Intelligence. To grow my skills, I focus on building JAM (Javascript API Markup) stack applications.
